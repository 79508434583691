import { RouteRecordRaw } from "vue-router";
import { RouteName } from "./routes/routeNames";

const redirects: RouteRecordRaw[] = [
    { path: '', redirect: { name: RouteName.Home } },
	{ path: '/', redirect: { name: RouteName.Home } },
	{ path: '/distributor', redirect: { name: RouteName.SelectScope } },
	{ path: '/provider', redirect: { name: RouteName.SelectScope } },
    { 
        name: RouteName.Distributor,
        path: '/distributor/:userId/',
        redirect: {
        name: RouteName.Home
    }
    },

    { 
        name: RouteName.Provider,
        path: '/provider/:userId/',
        redirect: {
        name: RouteName.Home
    },
    },
    {   name: RouteName.Backoffice,
        path: '/backoffice',
        redirect: { 
            name: RouteName.Home
        }
    }
];

export default redirects;