import Log from '@invidi/common-edge-logger-ui';
import {
	createRouter as createVueRouter,
	createWebHistory,
	Router,
} from 'vue-router';

import { createAuthGuard } from '@/routes/authGuard';
import { RouteName } from '@/routes/routeNames';
import Auth from '@/utils/auth';
import redirects from './redirects';
import { getScopeFromCookie } from './composables/useScope';
import { createAccountGuard } from './routes/accountGuard';


const topLogLocation = 'src/router.ts';

export type CreateRouterOptions = {
	log: Log;
};

export let router: Router;

export function createRouter(options: CreateRouterOptions): Router {
	const { log } = options;
	const logLocation = `${topLogLocation}: createRouter()`;

	log.notice('Creating Vue Router', { logLocation });

	const newRouter = createVueRouter({
		history: createWebHistory(String(import.meta.env.BASE_URL)),
		routes: [
			...redirects,
			{
				path: '/',
				component: () => import('@/pages/home/Home.vue'),
				name: RouteName.Home,				
			},
			{
				path: '/select-scope',
				component: () => import('@/components/modals/SelectScope.vue'),
				name: RouteName.SelectScope,
			},
			{
				component: () => import('@/pages/errors/AccessDenied.vue'),
				name: RouteName.AccessDenied,
				path: '/access-denied',
			},
			{
				path: '/about',
				component: () => import('@/pages/about/About.vue'),
				name: 'about',
			},
			{ //no need to cerate a new route for the provider and distributor scoped paths, just redirect them to this path
				path: '/networks/:net/:file/',
				component: () =>
					import('@/pages/FreeWheelVodGam/ADSConfigFileDetails.vue'),
				name: 'net',
			},
			// Final catch-all 404-view
			{
				component: () => import('@/pages/errors/NotFound.vue'),
				name: 'pageNotFound',
				path: '/:pathMatch(.*)*',
			},
		],
	});

	router = newRouter;

	return newRouter;
}

export function useNavigationGuards(options: { auth: Auth; log: Log }): void {

	router.beforeEach(createAuthGuard(options));

	router.beforeEach( async (to) => {

		if(to.name === RouteName.AccessDenied || to.name === RouteName.SelectScope){
			return; //allow user to go to access denied
		}
		
		if(to.name === RouteName.Home && !getScopeFromCookie()  ){ //log out
			return {name: RouteName.SelectScope }
		}

		if(!getScopeFromCookie()){//false links
			return {name: RouteName.AccessDenied};
		}
		return 
	});

	// The account guard must be run after the above two!
	router.beforeEach(createAccountGuard(options));
}

export default createRouter;
