export const BACKOFFICE_SCOPE_VALUE = 'configuration-platform:invidi';
export const LOCAL_DEV_ADMIN_VALUE = 'admin';
export const AUTH_BACKOFFICE_SCOPE_VALUE = 'auth-backoffice';


export enum UserTypeEnum {
	BACKOFFICE = 'backoffice',
	DISTRIBUTOR = 'distributor',
	PROVIDER = 'provider',
}

export class AuthScope {
	readonly userType: UserTypeEnum = null;
	readonly userId: string = null;

	private constructor(userType: UserTypeEnum, userId: string) {
		if (
			userId &&
			(userType === UserTypeEnum.DISTRIBUTOR ||
				userType === UserTypeEnum.PROVIDER)
		) {
			this.userId = userId;
			this.userType = userType;
		} else if (!userId && userType === UserTypeEnum.BACKOFFICE) {
			this.userType = userType;
		}
	}

	static create(userType: UserTypeEnum, userId: string): AuthScope {
		return new AuthScope(userType, userId);
	}

	static createEmpty(): AuthScope {
		return new AuthScope(null, null);
	}

	static createProvider(userId: string): AuthScope {
		return new AuthScope(UserTypeEnum.PROVIDER, userId);
	}

	static createDistributor(userId: string): AuthScope {
		return new AuthScope(UserTypeEnum.DISTRIBUTOR, userId);
	}

	static createBackoffice(): AuthScope {
		return new AuthScope(UserTypeEnum.BACKOFFICE, null);
	}

	isEqualTo(other: AuthScope): boolean {
		return (
			this === other ||
			(Boolean(other) &&
				this.userType === other.userType &&
				this.userId === other.userId)
		);
	}

	asBasePath(): string {
		if (this.isBackoffice()) {
			return `/${UserTypeEnum.BACKOFFICE}`;
		}

		if (this.isProvider() || this.isDistributor()) {
			return `/${this.userType}/${this.userId}`;
		}
		return '';
	}

	asString(): string {
		if (this.isBackoffice()) {
			return BACKOFFICE_SCOPE_VALUE;
		}
		if (this.isProvider() || this.isDistributor()) {
			return `${this.userType}:${this.userId}`;
		}
		return '';
	}

	isEmpty(): boolean {
		return !this.userType && !this.userId;
	}

	isProvider(): boolean {
		return Boolean(this.userId) && this.userType === UserTypeEnum.PROVIDER;
		
	}

	isDistributor(): boolean {
		return Boolean(this.userId) && this.userType === UserTypeEnum.DISTRIBUTOR;
	}

	isBackoffice(): boolean {
		return !this.userId && this.userType === UserTypeEnum.BACKOFFICE;
	}
}
