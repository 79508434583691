import { jwtDecode } from 'jwt-decode';

// import { RequestHeadersInterceptor } from '@/globals/api';
import log from '@/log';
import Auth from '@/utils/auth';
import { AuthScope } from '@/utils/authScope';
import { authScopeFromClaim } from '@/utils/authScopeUtils';

const topLogLocation = 'src/utils/authUtils.ts';

export type AuthAccountClaim = {
	label: string;
	authScope: AuthScope;
};

// export function createRequestHeadersAuthInterceptor(options: {
// 	auth?: Auth;
// 	getAuthScope: () => AuthScope;
// 	log: Log;
// }): RequestHeadersInterceptor {
// 	const { auth, getAuthScope } = options;
// 	return async (request): Promise<AxiosRequestHeaders> => {
// 		const { headers } = request;
// 		const newHeaders = new AxiosHeaders({ ...headers });
// 		newHeaders.set(requestTraceId, uuidv4());
// 		newHeaders.set(requestClientId, 'conexus-ui');

// 		if (headers.Authorization) {
// 			return newHeaders;
// 		}

// 		const authScope = getAuthScope();

// 		if (auth) {
// 			const accessToken = await auth.accessToken(authScope.asString());
// 			if (accessToken) {
// 				newHeaders.Authorization = `Bearer ${accessToken}`;
// 			}
// 		}

// 		return newHeaders;
// 	};
// }

type UserInfo = {
	accountId: string;
	accountName: string;
	accountType: string;
	email: string;
	id: string;
	name: string;
	realm?: string;
};

type AccessTokenClaims = {
	[key: string]: any;
	flags?: string;
	realm?: string;
};

export const getAccountClaims = async (
	auth: Auth
): Promise<AuthAccountClaim[]> => {
	const audienceClaims = await auth.audienceClaims();
	return audienceClaims
		.map((claim) => ({
			label: claim.label,
			authScope: authScopeFromClaim(claim.scopes?.[0]),
		}))
		.filter(({ label, authScope }) => label && !authScope.isEmpty());
};

export const getClaimFromAuthScope = async (
	auth: Auth,
	authScope: AuthScope
): Promise<AuthAccountClaim> => {
	if (authScope.isEmpty()) {
		return null;
	}
	const claims = await getAccountClaims(auth);
	return claims.find((claim) => authScope.isEqualTo(claim.authScope));
};

const getParsedAccessToken = async (
	auth: Auth,
	authScope: AuthScope
): Promise<AccessTokenClaims> => {
	const logLocation = `${topLogLocation}: getAccessTokenClaims`;
	if (!(await auth.isAuthenticated())) {
		return null;
	}
	const accessToken = await auth.accessToken(authScope.asString());
	if (!accessToken) {
		return null;
	}
	try {
		return jwtDecode<AccessTokenClaims>(accessToken);
	} catch (error) {
		log.error(`${logLocation} could not parse access token.`, {
			error,
		});
		return null;
	}
};

export const getRealm = async (
	auth: Auth,
	authScope: AuthScope
): Promise<string> => {
	const parsedToken = await getParsedAccessToken(auth, authScope);
	return parsedToken?.realm;
};

export const isAdmin = async (
	auth: Auth,
	authScope: AuthScope
): Promise<boolean> => {
	const parsedToken = await getParsedAccessToken(auth, authScope);
	return parsedToken?.flags
		?.split(',')
		?.some((flag: string) => flag === 'admin');
};

export const getUserInfo = async (
	auth: Auth,
	authScope: AuthScope
): Promise<UserInfo> => {
	const user = await auth.user();
	if (!user) {
		return null;
	}

	const [claim, realm] = await Promise.all([
		getClaimFromAuthScope(auth, authScope),
		getRealm(auth, authScope),
	]);

	const { userId: accountId, userType: accountType } = claim
		? authScope
		: ({} as AuthScope);

	return {
		accountId,
		accountName: claim?.label,
		accountType,
		email: user.email,
		id: user.sub,
		name: user.name,
		realm,
	};
};

// TODO: Remove this once inspex moves to its own service
/**
 * This function is temporary and is only meant to be used for inspex. Please
 * do not use this function for anything else as we should not be parsing
 * permissions from the token in the UI
 * @param {string} accessToken The JWT access token as a string
 * @returns {string[]} The list of permissions on the token
 */
export const getPermissionsFromToken = (
	accessToken: string
): string[] | null => {
	const logLocation = `${topLogLocation}: getPermissionsFromToken`;
	try {
		const decodedToken = jwtDecode<AccessTokenClaims>(accessToken);
		const claimNames = Object.keys(decodedToken);
		const permissionsKey = claimNames.find((claimName) =>
			/(https:\/\/mediahub.*\/permissions)/.test(claimName)
		);
		return decodedToken[permissionsKey];
	} catch (error) {
		log.error(`${logLocation} could not parse access token.`, {
			error,
		});
		return null;
	}
};
