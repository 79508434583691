<template>
	<nav id="main-left-nav">
		<router-link class="logo edge-logo" :to="{ name: 'root' }">
			<span class="sr-only">Root page</span>
			<SvgIcon name="edge-full" />
		</router-link>
		<ul>
			<li>
				<router-link class="link" :to="{ name: 'root' }">
					<SvgIcon name="configuration" />
					<span>Configuration</span>
				</router-link>
			</li>
		</ul>
		<ul class="help">
			<li>
				<a
					href="https://docs.invidi.com/r/Conexus-User-Guide"
					target="_blank"
					rel="noreferrer noopener"
					class="link"
				>
					<SvgIcon name="help-contextual" />
					<span>Help</span>
				</a>
			</li>
		</ul>
	</nav>
</template>

<script lang="ts">
export default {
	name: 'LeftNav',
};
</script>
