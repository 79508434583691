import Log from '@invidi/common-edge-logger-ui';
import type { RouteLocation, RouteLocationRaw } from 'vue-router';

import { RouteName } from '@/routes/routeNames';
import Auth, { AuthError } from '@/utils/auth';

const topLogLocation = 'src/routes/authGuardAuthRedirectHandler.ts';

export const isAuthRedirect = (route: RouteLocation): boolean => {
	const { code, error, state } = route.query;
	return Boolean(state && (code || error));
};

export const handleAuthRedirect = async ({
	auth,
	log,
	route,
}: {
	auth: Auth;
	log: Log;
	route: RouteLocation;
}): Promise<RouteLocationRaw> => {
	const {
		code,
		error,
		error_description: errorDescription,
		state,
	} = route.query;

	const logLocation = `${topLogLocation}: handleAuthRedirect`;

	if (error && state) {
		switch (error) {
			case AuthError.UNAUTHORIZED:
			case AuthError.ACCESS_DENIED:
				log.info('Auth redirected with access denied', {
					error,
					errorDescription,
					logLocation,
				});
				break;
			default:
				log.error('Auth redirected with error', {
					error,
					errorDescription,
					logLocation,
				});
				break;
		}

		return { name: RouteName.AccessDenied };
	}

	if (code && state) {
		const { error: redirectError, targetUrl } =
			await auth.handleRedirectCallback();

		if (redirectError) {
			log.error('Error handling the redirect callback', {
				error: redirectError.error,
				errorMessage: redirectError.message,
				logLocation,
			});

			return { name: RouteName.AccessDenied };
		}
		// Set datadog user here so that the login action has a user
		// await setDatadogUser(auth, log, pathToAuthScope(targetUrl));
		// addDatadogAction('login');
		return targetUrl || '/';
	}

	log.error('Unexpected state. No auth redirect to handle.', { logLocation });
};
