<template>
	<div class="sticky-bar" style="height: 44px">
		<div class="container">
			<div class="menu utility-menu below-left">
				<input id="account-select-bar-sticky" type="checkbox" />
				<label for="account-select-bar-sticky">
					<span>
						Environment&nbsp;<strong>{{ envToHumanReadable(environmentStore.getCurrentEnv()) }}</strong>
						<svg xmlns="http://www.w3.org/2000/svg" class="icon-carat-up" viewBox="0 0 32 32">
							<path fill="#5a7cd4" fill-rule="evenodd" d="M8 9l8 10 8-10z" />
						</svg>
					</span>
				</label>
				<div class="menu-content">
					<ul v-for="env in selectableEnvs(environmentStore.getAllEnvs())" :key="env">
						<li>
							<button :data-testid="env+`_env_button`" @click="updateCurrentEnv(env)">
								{{ envToHumanReadable(env) }}
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- <button data-testid="logout_button" class="button secondary" @click="logout"
			>Logout</button
		> -->
	</div>
</template>

<script setup lang="ts">
import { useEnvironmentStore } from '@/composables/useEnvironmentStore';

const environmentStore = useEnvironmentStore();

const selectableEnvs = (envs: string[]): string[] => envs.filter(obj => obj !== environmentStore.getCurrentEnv());

const updateCurrentEnv = (selectedEnv: string): void => {
	environmentStore.setCurrentEnv(selectedEnv);
};

const envToHumanReadable = (env: string): string => env.replace(/_/g, ' ');
</script>

<script lang="ts">
export default {
	name: "sticky-utility-menu"
}
</script>
