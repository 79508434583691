import axios, { AxiosHeaders, AxiosResponse } from 'axios';
import sampleAE from '@/sample/freewheel_sample/vodFreeWheelFile_AE.json';
import sampleAERevision from '@/sample/freewheel_sample/vodFreeWheelFile_AE_revision.json'
import type { ADSConfigFile } from '@/types/adsConfigFile';
import { Account, ADROUTER_APPLICATION_TYPE } from '@/types/account';
import { ConfigApiPaginatedResponse } from '@/types/configApiPaginatedResponse';

// TODO repo variable by realm
const CONFIG_PLATFORM_BASE_ROUTE = 'https://bsct6s3m5m.execute-api.us-east-2.amazonaws.com/config';

const GET_ACCOUNTS_API_PATH = `${CONFIG_PLATFORM_BASE_ROUTE}/`;

const __APIPaths = (endPoint: string, currentEnv: string): string => {
	const endPoints: Record<string, string> = {
		'POST_FreeWheel':
			`${CONFIG_PLATFORM_BASE_ROUTE}/${currentEnv}/adrouter/us-east-1/ads/freewheel`,
		'GET_FreeWheel':
			`${CONFIG_PLATFORM_BASE_ROUTE}/${currentEnv}/adrouter/us-east-1/ads/freewheel`,
	};

	return endPoints[endPoint];
};

const TokenKeys = (scope: String) => {
	return{
		IT: `@@auth0spajs@@::GDj1Wxs3jPF64NJPooCVhdZNOlFskc5f::https://mediahub.invidi.it::openid profile email offline_access ${scope}`,
		LOCAL_DEV:
			`@@auth0spajs@@::dummy-auth-mock-spa-client-id::https://mediahub.localdomain:4000::openid profile email offline_access ${scope}`
	}
};

export function fileData(content: string): Blob[] {
	const arrayOfBlob: Blob[] = [];
	const data = new Blob([content], { type: 'application/json' });
	arrayOfBlob.push(data);
	return arrayOfBlob;
}

export const flattenSingleNestedItem = (file: File, key: string, fileNameOverride?: string): Promise<File> => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.onload = (): void => {
		try {
			const result = reader.result as string;
			const fileJSON = JSON.parse(result);
			const subItems = fileJSON[key];
			delete fileJSON[key];
			for (const i in subItems) {
				fileJSON[i] = subItems[i];
			}
			const JSONBlob: Blob[] = fileData(JSON.stringify(fileJSON));
			const name = fileNameOverride ?? file.name;
			const modifiedFile = new File(JSONBlob, name);
			resolve(modifiedFile);
		} catch (error) {
			reject(error);
		}
	};
	reader.onerror = (): void => {
		reject(reader.error);
	};
	reader.readAsText(file);

});

export const getAccountsList = async (scope: string): Promise<string[]> => {
	const authToken = JSON.parse(localStorage.getItem(TokenKeys(scope).IT));
	const allAccounts: string[] = [];
	let nextPtr = null;

	do {
		// string possible in case of 204 response
		const platformResponse = await axios
			.get<ConfigApiPaginatedResponse<Account, number> | string>(GET_ACCOUNTS_API_PATH, {
				headers: {
					Authorization: `Bearer ${authToken.body.access_token}`
				},
				validateStatus: status => status === 200 || status === 204
			});

		const paginatedData = platformResponse.data as ConfigApiPaginatedResponse<Account, number>;

		if (platformResponse.status === 200) {
			paginatedData.items.forEach(account => {
				if (account.applicationType === ADROUTER_APPLICATION_TYPE) {
					allAccounts.push(account.tenantId);
				}
			});

			nextPtr = paginatedData.next;
		} else {
			nextPtr = null;
		}
	} while (nextPtr !== null);

	return allAccounts;
};

export const postUpdatedFreeWheelFile = async (freeWheelFile: File, currentEnv: string, scope: string, fileNameOverride?:string): Promise<AxiosResponse> => {
	const flattenedFile = await flattenSingleNestedItem(freeWheelFile, 'constants', fileNameOverride)
	const fd = new FormData();
	const authToken = JSON.parse(
		localStorage.getItem(TokenKeys(scope).IT) ||
		localStorage.getItem(TokenKeys(scope).LOCAL_DEV)
	);
	fd.append('body', flattenedFile);
	return await axios.post(__APIPaths('POST_FreeWheel',currentEnv), fd, {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${authToken.body.access_token}`,
		},
	}).then((response) => response)
	.catch((response) => response)
};

export const getFreeWheelFileRevision = async (
	freeWheelFileName: string, revision: number, currentEnv: string, scope:string
): Promise<[ADSConfigFile, AxiosHeaders]> => {
	try {
		const authToken = JSON.parse(localStorage.getItem(TokenKeys(scope).IT));
		return await axios
			.get<ADSConfigFile>(__APIPaths('GET_FreeWheel', currentEnv), {
				params: {
					name: freeWheelFileName,
					revision
				},
				headers: {
					Authorization: `Bearer ${authToken.body.access_token}`,
				},
			})
			.then((response) => [response.data as ADSConfigFile, response.headers as AxiosHeaders]);
	} catch {
		return [sampleAERevision, new AxiosHeaders('x-cp-revision:1')];
	}
}

export const getFreeWheelFile = async (
	freeWheelFileName: string, currentEnv: string, scope: string
): Promise<[ADSConfigFile, AxiosHeaders]> => {
	try {
		const authToken = JSON.parse(localStorage.getItem(TokenKeys(scope).IT));
		return await axios
			.get(__APIPaths('GET_FreeWheel', currentEnv), {
				params: {
					name: freeWheelFileName,
				},
				headers: {
					Authorization: `Bearer ${authToken.body.access_token}`,
				},
			})
			.then((response) => [response.data as ADSConfigFile, response.headers as AxiosHeaders]);

	} catch {
		return [sampleAE, new AxiosHeaders('x-cp-revision:2')];
	}
};
