import { parseJSON } from '@/utils/commonUtils';

const MAX_AUTH_ATTEMPTS = 2;
const AUTH_ATTEMPTS_SESSION_STORAGE_INDEX = 'authAttempts';

const toKey = (scope: string, withAudience: boolean): string =>
	`${scope || 'default'} ${withAudience}`;

const getAuthAttempts = (): Record<string, number> => {
	if (!sessionStorage.getItem(AUTH_ATTEMPTS_SESSION_STORAGE_INDEX)) {
		sessionStorage.setItem(AUTH_ATTEMPTS_SESSION_STORAGE_INDEX, '{}');
		return {};
	}
	try {
		return parseJSON(
			sessionStorage.getItem(AUTH_ATTEMPTS_SESSION_STORAGE_INDEX)
		);
	} catch {
		sessionStorage.setItem(AUTH_ATTEMPTS_SESSION_STORAGE_INDEX, '{}');
		return {};
	}
};

const getScopeAuthAttempts = (scope: string, withAudience: boolean): number =>
	getAuthAttempts()[toKey(scope, withAudience)] ?? 0;

export const increaseAuthAttempts = (
	scope: string,
	withAudience: boolean
): void => {
	const authAttempts = getAuthAttempts();
	authAttempts[toKey(scope, withAudience)] =
		getScopeAuthAttempts(scope, withAudience) + 1;
	sessionStorage.setItem(
		AUTH_ATTEMPTS_SESSION_STORAGE_INDEX,
		JSON.stringify(authAttempts)
	);
};

export const resetAuthAttempts = (): void => {
	sessionStorage.setItem(AUTH_ATTEMPTS_SESSION_STORAGE_INDEX, '{}');
};

export const maxAuthAttemptsReached = (
	scope: string,
	withAudience: boolean
): boolean => getScopeAuthAttempts(scope, withAudience) >= MAX_AUTH_ATTEMPTS;
