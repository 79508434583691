import {
	AuthScope,
	BACKOFFICE_SCOPE_VALUE,
	LOCAL_DEV_ADMIN_VALUE,
	AUTH_BACKOFFICE_SCOPE_VALUE,
	UserTypeEnum,
} from '@/utils/authScope';

export const pathToAuthScope = (path: string): AuthScope => {
	if (!path) {
		return AuthScope.createEmpty();
	}

	const parts = path.split('/').filter(Boolean);

	const userType = parts[0] as UserTypeEnum;

	if (!Object.values(UserTypeEnum).includes(userType)) {
		return AuthScope.createEmpty();
	}

	if (userType === UserTypeEnum.BACKOFFICE) {
		return AuthScope.createBackoffice();
	}

	const userId = parts[1];
	return userId ? AuthScope.create(userType, userId) : AuthScope.createEmpty();
};

export const authScopeFromCurrentLocation = (): AuthScope =>
	pathToAuthScope(window.location.pathname);

export const authScopeFromClaim = (claimScope: string): AuthScope => {
	if (!claimScope) {
		return AuthScope.createEmpty();
	}
	if (claimScope === BACKOFFICE_SCOPE_VALUE 
		|| claimScope === LOCAL_DEV_ADMIN_VALUE
		|| claimScope === AUTH_BACKOFFICE_SCOPE_VALUE
	) {
		return AuthScope.createBackoffice();
	}
	const parts = claimScope.split(':');
	if (parts.length > 2) {
		return AuthScope.createEmpty();
	}
	const [userType, userId] = parts as [UserTypeEnum, string];
	if (
		userId &&
		(userType === UserTypeEnum.DISTRIBUTOR ||
			userType === UserTypeEnum.PROVIDER)
	) {
		return AuthScope.create(userType, userId);
	}
	return AuthScope.createEmpty();
};
