import {
	Auth0Client,
	GetTokenSilentlyOptions,
	GetTokenWithPopupOptions,
	User,
} from '@auth0/auth0-spa-js';
import Log from '@invidi/common-edge-logger-ui';
import { App } from 'vue';
import { useCookies } from "vue3-cookies";
import { cookieNames } from '@/cookieNames';


const topLogLocation = 'src/utils/auth.ts';

// "useRefreshTokens: true" will always set "offline_access" scope, but
// we need to include it here to make sure the scopes are always in the same
// order (otherwise token cache keys might not match)
const DEFAULT_SCOPES = ['openid', 'profile', 'email', 'offline_access'];
const scopeToScopes = (scope: string): string[] => scope.split(' ');
const scopesToScope = (scopes: string[]): string =>
	scopes.filter(Boolean).join(' ');

const getAccountScope = (scope: string): string =>
	scopeToScopes(scope)
		.filter((scope) => !DEFAULT_SCOPES.includes(scope))
		.join(' ');

const resolveLogoutRedirectUrl = (
	config: Auth0Config,
	finalRedirectUrl: string
): string => {
	if (!config.brokerLogoutUrl) {
		return finalRedirectUrl;
	}
	const url = new URL(config.brokerLogoutUrl);
	url.searchParams.set('post_logout_redirect_uri', finalRedirectUrl);
	return url.toString();
};

export type Auth0Config = {
	audience: string;
	brokerLogoutUrl: string;
	clientId: string;
	domain: string;
	federatedLogout: boolean;
	redirectUri: string;
};

export enum AuthError {
	ACCESS_DENIED = 'access_denied',
	ACCOUNT_SELECTION_REQUIRED = 'account_selection_required',
	CONSENT_REQUIRED = 'consent_required',
	INTERACTION_REQUIRED = 'interaction_required',
	INVALID_GRANT = 'invalid_grant',
	LOGIN_REQUIRED = 'login_required',
	MISSING_REFRESH_TOKEN = 'missing_refresh_token',
	UNAUTHORIZED = 'unauthorized',
}

export type AuthOptions = {
	auth0Config: Auth0Config;
	log: Log;
};

export type AuthUser = User;

export type AuthAudienceClaim = {
	label: string;
	scopes: string[];
};

export default class Auth {
	private auth0Client: Auth0Client;
	private config: Auth0Config;
	private log: Log;

	constructor(options: AuthOptions) {
		this.config = options.auth0Config;
		this.log = options.log;
		this.createClient();
	}

	public install(app: App): void {
		app.provide('auth', this);
		app.config.globalProperties.$auth = this;
	}

	createClient(scope = '', withAudience = false): void {
		const { config, log } = this;
		const logLocation = `${topLogLocation}: createClient()`;

		if (
			!config.audience ||
			!config.clientId ||
			!config.domain ||
			config.federatedLogout === undefined ||
			!config.redirectUri
		) {
			log.error('Cannot create auth0 client, config is incomplete', {
				...config,
				logLocation,
			});

			return;
		}

		const audience = withAudience ? config.audience : undefined;

		log.notice('Creating auth0 client', {
			...config,
			audience,
			scope,
			logLocation,
		});
		try {
			this.auth0Client = new Auth0Client({
				advancedOptions: { defaultScope: scopesToScope(DEFAULT_SCOPES) },
				audience,
				cacheLocation: 'localstorage',
				client_id: config.clientId,
				domain: config.domain,
				redirect_uri: config.redirectUri,
				scope,
				useRefreshTokens: true,
				// If fallback is disabled (and no valid refresh-token exists),
				// authorization will be done in a redirect chain via the
				// login/auth0 server.
				// If fallback is enabled (and no valid refresh-token exists),
				// authorization will be done using the auth0 cookie in a hidden iframe.
				// If the cookie is not present (when the browser is blocking third-party
				// cookies), the redirect chain will happen anyway.
				useRefreshTokensFallback: false,
			});
		} catch (err) {
			log.error('Failed to create auth0 client', {
				...config,
				error: err.error,
				errorMessage: err.message,
				logLocation,
			});
			return;
		}
		log.debug('Auth0 client created', { logLocation });
	}

	async loginWithRedirect(
		targetUrl: string,
		scope: string,
		withAudience: boolean
	): Promise<void> {
		const { config, log } = this;
		const logLocation = `${topLogLocation}: loginWithRedirect()`;

		log.notice('Logging in with redirect', {
			...config,
			targetUrl,
			logLocation,
		});

		const appState = {
			audience: withAudience ? config.audience : undefined,
			scope,
			targetUrl,
		};

		try {
			if (scope || withAudience) {
				// We need to create a temporary client here before redirecting, since
				// scope and audience can not be passed to auth0Client.loginWithRedirect
				this.createClient(scope, withAudience);
			}
			await this.auth0Client.loginWithRedirect({ appState });
		} catch (err) {
			log.error('Failed to log in with redirect', {
				...config,
				...appState,
				error: err.error,
				errorMessage: err.message,
				logLocation,
			});
		}
	}

	async handleRedirectCallback(): Promise<{
		error?: Error | any;
		targetUrl?: string;
	}> {
		const { auth0Client, config, log } = this;
		const logLocation = `${topLogLocation}: handleRedirectCallback()`;

		log.notice('Handling redirect login callback', { ...config, logLocation });

		try {
			const result = await auth0Client.handleRedirectCallback();
			const { scope, targetUrl = '/' } = result.appState;
			log.notice('Redirect login callback handled', {
				scope,
				targetUrl,
				logLocation,
			});
			return { targetUrl };
		} catch (error) {
			log.error('Failed to handle redirect login callback', {
				...config,
				error: error.error,
				errorMessage: error.message,
				logLocation,
			});
			return { error };
		}
	}

	async accessToken(scope: string, withAudience = true): Promise<string> {
		const { auth0Client, log } = this;
		const logLocation = `${topLogLocation}: accessToken()`;
		const getTokenOptions: GetTokenWithPopupOptions | GetTokenSilentlyOptions =
			{
				audience: withAudience ? this.config.audience : undefined,
				// refreshAudience and refreshScope are custom parameters needed for
				// refresh tokens to work in our particular setup. Our auth0 login server
				// has a lot of custom code on top of the built-in auth0 stuff, which
				// somehow makes it impossible to resolve the audience and scope of the
				// original access token without some helpful parameters.
				refreshAudience: withAudience ? this.config.audience : '',
				refreshScope: scopesToScope([...DEFAULT_SCOPES, scope]),
				scope,
			};

		log.debug('Getting access token', {
			...getTokenOptions,
			logLocation,
		});

		try {
			const response = await auth0Client.getTokenSilently({
				...getTokenOptions,
				detailedResponse: true,
			});
			const responseScope = getAccountScope(response.scope);
			if (scope !== responseScope) {
				// The auth0-spa client is returning cached accessTokens even though
				// the scopes don't fully match. We need strict scope checks.
				// Example:
				// A cached token has scope "default provider:1".
				// We would like a token for backoffice and asks for scope "default".
				// Then we would get back the cached token for "default provider:1",
				// which doesn't work for backoffice api calls.
				log.info('Failed to get access token, scopes are not equal', {
					requestedScope: scope,
					returnedScope: responseScope,
					logLocation,
				});
				return undefined;
			}
			const accessToken = response.access_token;		
			log.debug('Got access token', {
				requestedScopes: getTokenOptions.scope,
				logLocation,
			});
			const {cookies} = useCookies();
			cookies.set(cookieNames.AccessToken, accessToken)
			return accessToken;
		} catch (err) {
			switch (err.error) {
				case AuthError.ACCOUNT_SELECTION_REQUIRED:
				case AuthError.ACCESS_DENIED:
				case AuthError.LOGIN_REQUIRED:
				case AuthError.INTERACTION_REQUIRED:
				case AuthError.MISSING_REFRESH_TOKEN:
				case AuthError.UNAUTHORIZED: {
					log.info('Failed to get access token with recoverable error', {
						error: err.error,
						errorMessage: err.message,
						logLocation,
					});
					return undefined;
				}
				case AuthError.CONSENT_REQUIRED: {
					log.notice('Consent required to get access token, showing popup', {
						requestedScopes: getTokenOptions.scope,
						logLocation,
					});
					try {
						const accessToken =
							await auth0Client.getTokenWithPopup(getTokenOptions);
						log.debug('Got access token from consent popup', {
							requestedScopes: getTokenOptions.scope,
							logLocation,
						});

						return accessToken;
						
					} catch (popupErr) {
						log.error('Failed to get access token with popup', {
							error: popupErr.error,
							errorMessage: popupErr.message,
							logLocation,
						});
					}
					return undefined;
				}
				case AuthError.INVALID_GRANT: {
					// https://datatracker.ietf.org/doc/html/rfc6749#section-5.2

					// The provided authorization grant (e.g., authorization code, resource owner credentials)
					// or refresh token is invalid, expired, revoked, does not match the redirection
					// URI used in the authorization request, or was issued to another client.

					// When this happens we clear the whole auth0 cache by doing a local logout.
					auth0Client.logout({
						localOnly: true,
					});
					log.error('Failed to get access token, clearing token cache.', {
						error: err.error,
						message: err.message,
						...getTokenOptions,
						logLocation,
					});
					return undefined;
				}
				default: {
					log.error('Failed to get access token', {
						...getTokenOptions,
						error: err.error,
						message: err.message,
						logLocation,
					});
					return undefined;
				}
			}
		}
	}

	logout(): void {
		const {cookies} = useCookies();
		if(cookies.get(cookieNames.Scope)){
			cookies.remove(cookieNames.Scope)
			}
		const { auth0Client, config, log } = this;
		const logLocation = `${topLogLocation}: logout()`;
		const returnTo = resolveLogoutRedirectUrl(config, window.location.origin);

		log.notice('Logging out', { ...config, returnTo, logLocation });

		try {
			auth0Client.logout({
				federated: config.federatedLogout,
				returnTo,
			});
		} catch (err) {
			log.error('Failed to log out', {
				...config,
				error: err.error,
				errorMessage: err.message,
				logLocation,
			});
		}
	}

	async isAuthenticated(): Promise<boolean> {
		const isAuthenticated =
			(await this.auth0Client?.isAuthenticated()) ?? false;

		this.log.debug('isAuthenticated()', {
			isAuthenticated: String(isAuthenticated),
		});

		return isAuthenticated;
	}

	async user(): Promise<AuthUser | null> {
		return await this.auth0Client?.getUser();
	}

	async audienceClaims(): Promise<AuthAudienceClaim[]> {
		const { config, log } = this;
		const logLocation = `${topLogLocation}: audienceClaims()`;
		const idTokenClaims = await this.auth0Client?.getIdTokenClaims();
		const claims =
			idTokenClaims?.[`${config.audience}/scopes`] ??
			([] as AuthAudienceClaim[]);

		log.debug('Got audience claims from ID token', {
			audience: config.audience,
			claims,
			logLocation,
		});

		return claims;
	}
}
