import Log from '@invidi/common-edge-logger-ui';

import Auth, { Auth0Config } from '@/utils/auth';

const topLogLocation = 'src/globals/config.ts';

export type AppConfigOpts = {
	appBasePath: string;
	currency: string;
	dateFormat: string;
	dateTimeFormat: string;
	auth0Config: Auth0Config;
	locale: string;
	logColors: boolean;
	logLevel: string;
	logOutputType: string;
	timeZone?: string;
};

export class AppConfig {
	appBasePath: string;
	auth0Config: Auth0Config;
	currency: string;
	dateFormat: string;
	dateTimeFormat: string;
	locale: string;
	logColors: boolean;
	logLevel: string;
	logOutputType: string;
	timeZone?: string;
	userManagementUrl: string;

	constructor(opts: AppConfigOpts) {
		this.appBasePath = opts.appBasePath;
		this.auth0Config = opts.auth0Config;
		this.currency = opts.currency;
		this.dateFormat = opts.dateFormat;
		this.dateTimeFormat = opts.dateTimeFormat;
		this.locale = opts.locale;
		this.logColors = opts.logColors;
		this.logLevel = opts.logLevel;
		this.logOutputType = opts.logOutputType;
	}
}

export let config: AppConfig = null;

function setConfig(newConfig: AppConfig, log: Log, auth: Auth): void {
	config = newConfig;
}

export function updateConfig(
	newConfig: Partial<AppConfigOpts>,
	log: Log,
	auth: Auth
): void {
	const logLocation = `${topLogLocation}: updateConfig()`;

	log.notice('Updating config variables', {
		logLocation,
		newConfig: JSON.stringify(newConfig),
	});
	setConfig(new AppConfig({ ...config, ...newConfig }), log, auth);
}

export function setInitialConfig(
	initialConfig: AppConfig,
	log: Log,
	auth: Auth
): void {
	const logLocation = `${topLogLocation}: setInitialConfig()`;

	log.notice('Setting initial config variables', {
		logLocation,
		initialConfig: JSON.stringify(initialConfig),
	});
	setConfig(initialConfig, log, auth);
}
