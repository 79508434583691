import { InputSelectOption } from '@invidi/conexus-component-library-vue';
import { computed, Ref, watch, ref } from 'vue';
import { sortByLabelAsc } from '@/utils/sortUtils';
import { useAuth } from '@/composables/useAuth';
import { useRoute } from 'vue-router';
import { AuthScope } from '@/utils/authScope';
import { authScopeFromClaim, authScopeFromCurrentLocation } from '@/utils/authScopeUtils'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { cookieNames } from '@/cookieNames';





 type UseScopes = {
    claims: Ref<InputSelectOption[]>; //relative to accounts
    activeScope?: Ref<string>;
    isAuthenticated:  Ref<boolean>;
    changeScope: (selectedClaim: Ref <string>
    ) => Promise <void>;

};

const useScopes = async (): Promise<UseScopes> => {
    const auth = useAuth();
    const route = useRoute();
    const activeScope = ref<string>();
    const isAuthenticated = ref(false); //has the user previously selected a scope
    const audienceClaims = await auth.audienceClaims() //claims contained in the id token
    const claims = computed <InputSelectOption[]>(()=>
        audienceClaims.map(({label}) => ({
            label,
            value: label
        })).sort(sortByLabelAsc)
    )

    const changeRouteFromScope = async (
        authScope: AuthScope
    ): Promise<void> =>  {
        
        let path = `${authScope.asBasePath()}/`; 
        await auth.loginWithRedirect(path, authScope.asString(), true) //get permssioned token and push
        
    }

 
const changeScope = async (selectedLabel: Ref<string> ) : Promise <void> => {
    const correspondignClaim = audienceClaims.find((claim) =>
        claim.label == selectedLabel.value
        );
    //create authScope from matched claim
    console.log(
        `Use Scope correspondingClaim:\n Label:${correspondignClaim.label}\nScope:${correspondignClaim.scopes[0]} Actual length  =  ${correspondignClaim.scopes.length}`

    ); 

    const authScopeFromMatchedClaim = authScopeFromClaim(correspondignClaim.scopes[0])
    console.log(`AuthScope from Matched Claim:\nUser ID: ${authScopeFromMatchedClaim.userId}\nUserType:${authScopeFromMatchedClaim.userType}`)
    storeScopeinCookie(authScopeFromMatchedClaim.asString()) 

    await changeRouteFromScope(authScopeFromMatchedClaim);
}

const setActiveScope = (): void => {
    const userScope = authScopeFromCurrentLocation();
    const userClaim = audienceClaims.find((claim) =>
        claim.scopes[0].includes(userScope.userId) && claim.scopes[0].includes(userScope.userType)
    )
    console.log(`Userscope from current location: \n UserID: ${userScope.userId} \n UserType: ${userScope.userType}`)
    console.log(`User Claim from set Active Scope \nLabe ${userClaim?.label}\n Scope: ${userClaim?.scopes[0]}`)
    activeScope.value = userClaim?.scopes[0] || ''
    console.log(`Active Scope: ${activeScope.value}`)
        
}

const storeScopeinCookie = (correspondignClaim: string) : void => {
    cookies.set(cookieNames.Scope, correspondignClaim )
}



const checkIfAuthed = async (): Promise<void> => {
        isAuthenticated.value = !getScopeFromCookie();
};

 watch(
     route,
     async() => {
        if(isAuthenticated.value){
            await checkIfAuthed();
            await changeRouteFromScope(authScopeFromClaim(getScopeFromCookie()))
        }
        setActiveScope();
     },
     {immediate: true}
)

return {
    claims,
    changeScope,
    isAuthenticated,
    activeScope
}

}

export const getScopeFromCookie = () : string  => {
    if(cookies.get(cookieNames.Scope)){
        console.log()
        return cookies.get(cookieNames.Scope)
    }
    return undefined
}

export default useScopes;