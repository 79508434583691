import { ParamsSerializerOptions } from 'axios';

// Used to verify that we don't miss a case, see
// https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(x: never): never {
	throw new Error(`Didn't expect to get here ${x}`);
}

export const axiosParamsSerializer: ParamsSerializerOptions = {
	indexes: null,
};

export const typedObjectEntries = <T extends object>(
	object: T
): [keyof T, T[keyof T]][] => Object.entries(object) as [keyof T, T[keyof T]][];

export const truncateText = (str: string, num: number): string => {
	if (str.length <= num) {
		return str;
	}
	const limiter = 9;
	const fH = str.slice(0, limiter);
	const sH = str.slice(str.length - limiter, str.length);
	// Return str truncated with '...' concatenated to the end of str.
	return `${fH}⋯${sH}`;
};

export const groupBy = <T, K extends keyof any>(
	items: T[],
	keyFunction: (item: T) => K
): Record<K, T[]> =>
	items.reduce(
		(result, item) => {
			const value = keyFunction(item);
			result[value] = (result[value] || []).concat(item);
			return result;
		},
		{} as Record<K, T[]>
	);


export const mapByKeyToValue = <T, K extends keyof any, V = T>(
	items: T[],
	keyFunction: (item: T) => K,
	valueFunction?: (item: T) => V
): Record<K, V> =>
	items.reduce(
		(result, item) => ({
			...result,
			[keyFunction(item)]: valueFunction ? valueFunction(item) : item,
		}),
		{} as Record<K, V>
	);

/* istanbul ignore next */
export const sleep = (ms: number): Promise<void> =>
	new Promise((resolve) => setTimeout(resolve, ms));

/*
Extracts all string (or W) keys that has value type V from a Record type T
Example:
T = { a: string, b: string[], c: boolean, d: string, 1: string}
KeyWithTypedValue<T, string> = "a" | "d"
KeyWithTypedValue<T, string[]> = "b"
KeyWithTypedValue<T, boolean> = "c"
KeyWithTypedValue<T, number> = never
KeyWithTypedValue<T, string, number> = 1
KeyWithTypedValue<T, string, number | string> =  "a" | "d" | 1
 */
export type KeyWithTypedValue<
	T extends Record<any, any>,
	V,
	W = string,
> = keyof {
	[P in keyof T as T[P] extends V ? P : never]: P;
} &
	W;

export const typeGuards = {
	isHTMLElement: (element: Element): element is HTMLElement =>
		(element as HTMLElement)?.blur !== undefined,
};

export const parseJSON = <T>(jsonString: string): T =>
	JSON.parse(jsonString) as T;

export const isNullOrUndefined = (toCheck: unknown): boolean =>
	toCheck === null || toCheck === undefined;

export const getUniqueItemsByKey = <T extends Record<any, any>>(
	items: T[],
	key: KeyWithTypedValue<T, string | number | boolean>
): T[] => Array.from(new Map(items.map((item) => [item[key], item])).values());

export const getUniqueItems = <T extends string | number | boolean>(
	items: T[]
): T[] => Array.from(new Set(items));

type Entry<T> = {
	[K in keyof T]: [K, T[K]];
}[keyof T];

export function filterObject<T extends object>(
	obj: T,
	fn: (entry: Entry<T>, i: number, arr: Entry<T>[]) => boolean
): Partial<T> {
	return Object.fromEntries(
		(Object.entries(obj) as Entry<T>[]).filter(fn)
	) as Partial<T>;
}
