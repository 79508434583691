import { defineStore } from "pinia";
import { ref } from 'vue';
import { useCookies } from "vue3-cookies";
import { cookieNames } from "@/cookieNames";

export const CURRENT_ENV_EMPTY = '';

export const useEnvironmentStore = defineStore('environments', () => {
	const available_environments = ref<string[]>([]);
	const selected_environment = ref<string>(CURRENT_ENV_EMPTY);
	const { cookies } = useCookies();

	const setAllEnvs = (allEnvs: string[]):void => {
		available_environments.value = allEnvs;
	}

	const getAllEnvs = (): string[] => available_environments.value

	const setCurrentEnv = (currEnv: string): void => {
		if (available_environments.value.includes(currEnv)) {
			selected_environment.value = currEnv;
			cookies.set(cookieNames.Environment, currEnv);
		}
	}

	const getCurrentEnv = (): string => selected_environment.value

	return {selected_environment, setAllEnvs, getAllEnvs, setCurrentEnv, getCurrentEnv}
})