<template>
	<nav id="main-right-nav">
		<UserMenu v-if="authenticated" />
	</nav>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { START_LOCATION, useRouter } from 'vue-router';

import UserMenu from '@/components/menu/UserMenu.vue';
import { useAuth } from '@/composables/useAuth';

const auth = useAuth();
const router = useRouter();
const authenticated = ref(false);

const setAuthenticated = async (): Promise<void> => {
	if (router.currentRoute.value === START_LOCATION) {
		authenticated.value = false;
	} else {
		authenticated.value = await auth.isAuthenticated();
	}
};

watch(router.currentRoute, setAuthenticated, { immediate: true });
</script>
