export enum RouteName {
	Networks = 'networks',
	About = 'about',
	AccessDenied = 'accessDenied',
	Home = 'root',
	SelectScope = 'select-scope',
	Distributor = 'distributor',
	Provider = 'provider',
	Backoffice = 'backoffice'
}
