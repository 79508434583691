export enum SortDirection {
	Asc = 'ASC',
	Desc = 'DESC',
}

type Option = {
	label: string;
};

export const sortByAsc = (a: string, b: string): number =>
	String(a).localeCompare(b, undefined, {
		numeric: true,
	});

export const sortByLabelAsc = (a: Option, b: Option): number =>
	sortByAsc(a.label, b.label);
