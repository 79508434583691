import { Ref, ref, watch } from 'vue';
import { START_LOCATION, useRouter } from 'vue-router';

import { AuthScope } from '@/utils/authScope';
import { pathToAuthScope } from '@/utils/authScopeUtils';

const useAuthScope = (): Ref<AuthScope> => {
	const { currentRoute } = useRouter();

	const authScope = ref(pathToAuthScope(currentRoute.value.path));

	watch(currentRoute, (newRoute, oldRoute) => {
		const newAuthScope = pathToAuthScope(newRoute.path);
		if (
			oldRoute === START_LOCATION ||
			!newAuthScope.isEqualTo(authScope.value)
		) {
			authScope.value = newAuthScope;
		}
	});

	return authScope;
};

export default useAuthScope;
