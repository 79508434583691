<template>
	<UtilityMenu
		menuId="logout-menu"
		:placement="MenuPlacement.LeftTop"
		class="user-nav-item"
	>
		<template #trigger>
			<span class="avatar text" data-testid="avatar-text" :title="title">
				{{ userInitials }}
			</span>
		</template>
		<template #body>
			<ul>
				<li>
					<button data-testid="logout-button" @click="logout">Logout</button>
				</li>
			</ul>
		</template>
	</UtilityMenu>
</template>

<script setup lang="ts">
import {
	MenuPlacement,
	UtilityMenu,
} from '@invidi/conexus-component-library-vue';
import { computed, ref, watch } from 'vue';

import { useAuth } from '@/composables/useAuth';
import useAuthScope from '@/composables/useAuthScope';
import { getClaimFromAuthScope } from '@/utils/authUtils';

const auth = useAuth();
const userFullName = ref<string>(null);
const accountName = ref<string>(null);

const authScope = useAuthScope();

const userInitials = computed(() =>
	userFullName.value
		? userFullName.value
				.split(' ')
				.slice(0, 2)
				.map((namePart) => namePart.charAt(0).toUpperCase())
				.join('')
		: null
);

const title = computed(() =>
	[userFullName.value, accountName.value].filter(Boolean).join(' - ')
);

const setAccountName = async (): Promise<void> => {
	const claim = await getClaimFromAuthScope(auth, authScope.value);
	accountName.value = claim?.label;
};

const setUserFullName = async (): Promise<void> => {
	const user = await auth.user();
	userFullName.value = user.name;
};

const logout = (): void => auth.logout();

setUserFullName();

watch(authScope, setAccountName, { immediate: true });
</script>
