import { useEnvironmentStore } from "@/composables/useEnvironmentStore";
import Auth from "@/utils/auth";
import { getAccountsList } from "@/utils/platformConfigDao";
import Log from "@invidi/common-edge-logger-ui";
import { NavigationGuardWithThis } from "vue-router";
import { useCookies } from "vue3-cookies";
import { RouteName } from "./routeNames";
import { getScopeFromCookie } from "@/composables/useScope";
import { ToastType, useToastsStore } from "@invidi/conexus-component-library-vue";
import { cookieNames } from "@/cookieNames";

const { cookies } = useCookies();

// This navigation guard gets the environments (e.g., labs, prod) available to the current principal and populates
// the global environment store with them. Access is denied if the user does not have any associated environments.
// This guard assumes that the auth guard has already run, the current principal's access token is
// available in local storage, and the principal's scope is stored in the expected cookie.

// This Config API call could not be done in App.vue, as the "full" auth token containing the right scope and
// permissions wasn't available in its setup script.
export const createAccountGuard = (options: {
	auth: Auth;
	log: Log;
}): NavigationGuardWithThis<undefined> => {
    const { log } = options;

    return async (to) => {
        switch (to.name) {
            case RouteName.AccessDenied:
            case RouteName.SelectScope: {
                log.debug('Skipping environment fetch for exempted route', { route: to.name });
                return;
            }
        }

        const environmentStore = useEnvironmentStore();
        const existingEnvironments = environmentStore.getAllEnvs();

        if (existingEnvironments.length > 0) {
            // Assumes selected_environment is also set in the environment store
            log.debug('Environments already available. Skipping API call', { environments: existingEnvironments });
            return;
        }

        const principalScope = getScopeFromCookie();

        try {
            const accounts = await getAccountsList(principalScope);

            if (accounts.length > 0) {
                environmentStore.setAllEnvs(accounts);

                const envCookie = cookies.get(cookieNames.Environment);

                if (typeof envCookie === 'string' && envCookie.length > 0) {
                    log.debug('Attempting to set environment based on existing cookie', { envCookie });
                    environmentStore.setCurrentEnv(envCookie);
                } else {
                    log.debug('Setting current environment to first available', { environment: accounts[0] });
                    environmentStore.setCurrentEnv(accounts[0]);
                }
            } else {
                // No accounts are associated with the current principal's scope
                log.error('No environments available to current principal');
                return { name: RouteName.AccessDenied };
            }
        } catch (error) {
            // Don't redirect to the access denied page (API error, not the user's scope issue)
            log.error('Failed to retrieve environments', { error });

            const toastsStore = useToastsStore();

            toastsStore.add({
                title: 'Error Fetching Environments',
                body: 'Please reload the page and try again.',
                type: ToastType.ERROR
            });
        }
    };
};
