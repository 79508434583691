import Log from '@invidi/common-edge-logger-ui';
import { NavigationGuardWithThis } from 'vue-router';

import {
	increaseAuthAttempts,
	maxAuthAttemptsReached,
	resetAuthAttempts,
} from '@/routes/authAttemptUtils';
import {
	handleAuthRedirect,
	isAuthRedirect,
} from '@/routes/authGuardAuthRedirectHandler';
import { RouteName } from '@/routes/routeNames';
import Auth from '@/utils/auth';
import { AuthScope } from '@/utils/authScope';
import { pathToAuthScope } from '@/utils/authScopeUtils';

export const createAuthGuard = (options: {
	auth: Auth;
	log: Log;
}): NavigationGuardWithThis<undefined> => {
	const { auth, log } = options;

	return async (to) => {
		if (isAuthRedirect(to)) {
			return await handleAuthRedirect({ auth, log, route: to });
		}

		if (to.name === RouteName.AccessDenied) {
			resetAuthAttempts();
			return;
		}

		const routeAuthScope = pathToAuthScope(to.path);

		// We need two sets of tokens to be able to properly load a page.
		// One without audience, since the idToken does not have claims
		// if audience is set (we need claims for the account-selectors).
		// One with audience, since an accessToken without audience is not
		// accepted in the apis.
		const requiredTokens = [
			{ authScope: AuthScope.createEmpty(), withAudience: false },
			{ authScope: routeAuthScope, withAudience: true },
		];

		for (const { authScope, withAudience } of requiredTokens) {
			if (!(await auth.accessToken(authScope.asString(), withAudience))) {
				if (maxAuthAttemptsReached(authScope.asString(), withAudience)) {
					// This will probably never happen. But if we get here, we are
					// stuck in a redirect loop of failed auth attempts.
					// So we end that loop here, before it becomes infinite.
					log.error('Maximum number of auth attempts reached.', {
						authScope: authScope.asString(),
					});
					return { name: RouteName.AccessDenied };
				}
				increaseAuthAttempts(authScope.asString(), withAudience);
				await auth.loginWithRedirect(
					to.fullPath,
					authScope.asString(),
					withAudience
				);
				return false;
			}
		}

		resetAuthAttempts();
	};
};
